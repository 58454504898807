






































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import { searchAggregatesByRelation, getVendors, getIndigenousCommunities } from '../store/rest.service';
import { ResourceSearchResult, Vendor, ProjectSearchCriteria, GeneralSearchCriteria, IndigenousCommunity } from '../store/models';
import { EmptyProjectSearchCriteria, EmptyGeneralSearchCriteria } from '../store/models-empty';
import { VendorCategory } from './PayeeHelper';
import SpinnerComponent from './SpinnerComponent.vue';
import ContactDetailsComponent from './ContactDetailsComponent.vue';
import DiversityInfoSummaryComponent from './DiversityInfoSummaryComponent.vue';
import PayeeHelper from './PayeeHelper';

import * as _ from 'lodash';

@Component({
  components: {
    SpinnerComponent,
    ContactDetailsComponent,
    DiversityInfoSummaryComponent,
  },
})
export default class PayeeDetailsComponent extends BaseComponent {

  @Prop() public id: any;
  @Prop() public vendorType: any;
  @Prop() public vendorTypeDescription: any;

  private payeeLoading: boolean = false;
  private vendorAggregateLoading: boolean = false;
  private editedItem?: Vendor|IndigenousCommunity|null = null;
  private vendors: ResourceSearchResult|null = null;
  private indigenousCommunities: ResourceSearchResult|null = null;
  private vendorAggregate: ResourceSearchResult|null = null;
  private vendorAggregateUpdated: number = 0;
  private helper: PayeeHelper = new PayeeHelper(VendorCategory.None);

  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);

  protected mounted() {
    this.helper.getDiversityReferenceData();
    this.helper.getDefaultExpenseClasses();
    this.helper.getIndigenousCommunities();
    this.getVendor();
  }

  private profileConnected() {
    this.$emit('profile-connected');
  }

  private profileDisconnected() {
    this.$emit('profile-disconnected');
  }

  private editVendor() {
    this.$emit('edit-vendor');
  }

  private editDiversityInfo() {
    this.$emit('edit-diversity-info');
  }

  private getVendor() {
    this.payeeLoading = true;
    getVendors(this.mapSearchParams({ sortBy: 'default', descending: false, page: 1, rowsPerPage: 1 }, '', []), this.id)
    .then((response) => {
      this.vendors = response;
      this.editedItem = response.searchResults.results.length > 0 ? response.searchResults.results[0] as Vendor : null;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.payeeLoading = false;
    });
  }

  private getIndigenousCommunity() {
    this.payeeLoading = true;
    getIndigenousCommunities(this.mapSearchParams({ sortBy: 'default', descending: false, page: 1, rowsPerPage: 1 }, '', []), this.id)
    .then((response) => {
      this.indigenousCommunities = response;
      this.editedItem = response.searchResults.results.length > 0 ? response.searchResults.results[0] as IndigenousCommunity : null;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.payeeLoading = false;
    });
  }

  private getVendorAggregate() {
    this.vendorAggregateLoading = true;
    searchAggregatesByRelation(this.searchCriteria, this.vendorType, this.currentOrg, this.id)
    .then((response) => {
      this.vendorAggregate = response;
      this.vendorAggregateUpdated ++;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.vendorAggregateLoading = false;
    });
  }

  private editProject(item: any) {
    if (item.subproject ? item.subproject.creatorVendor === this.currentOrg : false) {
      this.$router.push({ path: '/projects/id/' + item.common.identifier });
    } else if (item.subproject && item.subproject.parentProject) {
      this.$router.push({ path: '/projects/id/' + item.subproject.parentProject });
    }
  }
}
