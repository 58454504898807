




























































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import * as _ from 'lodash';

@Component({
  components: {
  },
})
export default class ImpactCategoriesComponent extends BaseComponent {

  @Prop() private vendor: any;
  @Prop() private helper: any;

  protected mounted() { }
}
