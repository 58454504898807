





















































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContactDetailsComponent extends Vue {

  @Prop() public organizationMeta: any;

}
