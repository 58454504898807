











































































































import { Component, Watch, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import ErrorComponent from "./ErrorComponent.vue";
import DatePickerComponent from "./DatePickerComponent.vue";
import ValidationSummaryComponent from "./ValidationSummaryComponent.vue";
import { getVendor, createVendor, updateVendor } from "../store/rest.service";
import { Vendor, OrganizationMeta, VendorMeta, AddressMeta, DiversityDeclarationsMeta, DiversityRefDataCategory, CommonMeta } from "../store/models";
import { EmptyAddressMeta, EmptyDiversityDeclarationMeta, EmptyDiversityDeclarationsMeta, EmptyVendor } from "../store/models-empty";
import PayeeHelper from "./PayeeHelper";
import { VendorCategory } from "./PayeeHelper";
import { handleExtraErrors } from "../lib/shared";
import SpinnerComponent from "./SpinnerComponent.vue";
import { rules } from "../lib/validation";

import * as _ from "lodash";

@Component({
  components: {
    ErrorComponent,
    ValidationSummaryComponent,
    SpinnerComponent,
    DatePickerComponent,
  },
})
export default class DiversityInfoComponent extends BaseComponent {
  @Prop() private vendorId: any;

  private helper: PayeeHelper = new PayeeHelper(VendorCategory.None);
  private helperLoading: boolean = true;

  private vendorSaving: boolean = false;

  private isSmallEnterprise: boolean = false;
  private smeCode: string = "sme";

  private editedItem: Vendor = _.cloneDeep(EmptyVendor);
  private editedVendorId: string = "";
  private vendorLoading: boolean = false;

  private arrErrors: Error[] = [];
  private extraErrors: Array<[string, string]> = [];
  private updateValidation: number = 0;

  private rowsPerPage: number[] = [3, 10, 25];

  private newContact: any = {};

  protected mounted() {
    if (this.vendorId !== "") {
      this.editVendor(this.vendorId);
    } else {
      this.initVendor();
    }
  }

  @Watch("helper.isLoading")
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
  }

  private isLoading(): boolean {
    return this.vendorLoading || this.helperLoading;
  }

  private updateIsSmallEnterprise() {
    const impactStatus: string[] = this.editedItem.diversityDeclarations.impactStatus ? this.editedItem.diversityDeclarations.impactStatus.values : [];
    if (this.isSmallEnterprise) {
      if (!impactStatus.includes(this.smeCode)) {
        impactStatus.push(this.smeCode);
      }
    } else {
      impactStatus.forEach((item, index) => {
        if (item === this.smeCode) {
          impactStatus.splice(index, 1);
        }
      });
    }
  }

  private addContact() {
    //TODO:
  }

  private updateEditedVendor(id: string) {
    this.editedVendorId = id;
    this.getVendor(this.editedVendorId);
    this.populateDataLists();
  }

  private editVendor(id: string) {
    this.updateEditedVendor(id);
  }

  private populateDataLists() {
    this.helper.getDefaultExpenseClasses();
    this.helper.getIndigenousCommunities(["urn:nisto-link:id:non-indigenous:no-999999"]);
    this.helper.getDiversityReferenceData();
  }

  private initVendor() {
    this.populateDataLists();
    this.editedItem = _.cloneDeep(EmptyVendor);
    const vendorMeta = this.editedItem.vendor as VendorMeta;
    vendorMeta.vendorState = "provisional";
  }

  private closeVendor() {
    this.$emit("close");
  }

  private save() {
    if (!this.vendorSaving) {
      if ((this.$refs.form1 as any).validate()) {
        this.updateValidation++;
        this.editedVendorId === "" ? this.createVendor() : this.updateVendor();
      } else {
        this.updateValidation++;
      }
    }
  }

  private getVendor(id: string) {
    this.vendorLoading = true;
    getVendor(id)
      .then((response) => {
        this.editedItem = response;
        const orgMeta: OrganizationMeta = this.editedItem.organization as OrganizationMeta;
        if (!orgMeta.addresses) {
          orgMeta.addresses = [];
        }
        const addressMeta = orgMeta.addresses as AddressMeta[];
        if (addressMeta.length === 0) {
          addressMeta[0] = EmptyAddressMeta;
        }
        // Fix the annoying diversityDeclarions bug.
        const declarions: DiversityDeclarationsMeta = (this.editedItem as any).diversityDeclarions;
        if (declarions) {
          if (!this.editedItem.diversityDeclarations) {
            this.editedItem.diversityDeclarations = EmptyDiversityDeclarationsMeta;
          }
          if (declarions.indigenousImpact) {
            this.editedItem.diversityDeclarations.indigenousImpact = _.cloneDeep(declarions.indigenousImpact);
          }
          if (declarions.impactESG) {
            this.editedItem.diversityDeclarations.impactESG = _.cloneDeep(declarions.impactESG);
          }
          if (declarions.impactStatus) {
            this.editedItem.diversityDeclarations.impactStatus = _.cloneDeep(declarions.impactStatus);
          }
          if (declarions.impactSocial) {
            this.editedItem.diversityDeclarations.impactSocial = _.cloneDeep(declarions.impactSocial);
          }
          if (declarions.socialFocusedOrgs) {
            this.editedItem.diversityDeclarations.socialFocusedOrgs = _.cloneDeep(declarions.socialFocusedOrgs);
          }
          delete (this.editedItem as any).diversityDeclarions;
        }
        // Add Empty diversityDeclarations.
        const declarations: DiversityDeclarationsMeta = this.editedItem.diversityDeclarations;
        if (!declarations.indigenousImpact) {
          declarations.indigenousImpact = _.cloneDeep(EmptyDiversityDeclarationMeta);
        }
        if (!declarations.impactESG) {
          declarations.impactESG = _.cloneDeep(EmptyDiversityDeclarationMeta);
        }
        if (!declarations.impactStatus) {
          declarations.impactStatus = _.cloneDeep(EmptyDiversityDeclarationMeta);
        }
        if (!declarations.impactSocial) {
          declarations.impactSocial = _.cloneDeep(EmptyDiversityDeclarationMeta);
        }
        if (!declarations.socialFocusedOrgs) {
          declarations.socialFocusedOrgs = _.cloneDeep(EmptyDiversityDeclarationMeta);
        }
        // Set the Small Enterprise flag.
        this.isSmallEnterprise = this.editedItem.diversityDeclarations.impactStatus ? this.editedItem.diversityDeclarations.impactStatus.values.includes(this.smeCode) : false;
      })
      .catch((error) => {
        this.$emit("error", error);
      })
      .finally(() => {
        this.vendorLoading = false;
      });
  }

  private createVendor() {
    this.vendorSaving = true;
    const orgMeta = this.editedItem.organization as OrganizationMeta;
    createVendor(this.editedItem)
      .then((response) => {
        this.closeVendor();
        this.$emit("save", response);
      })
      .catch((error) => {
        this.handleVendorExtraErrors(error);
      })
      .finally(() => {
        this.vendorSaving = false;
      });
  }

  private updateVendor() {
    this.vendorSaving = true;
    const vendorETag = (this.editedItem.common as CommonMeta).etag as string;
    const vendorToSave = this.removeCommonProperties(_.cloneDeep(this.editedItem));
    const orgMeta = vendorToSave.organization as OrganizationMeta;
    // Convert this to a string as sometimes it is a number.
    if (orgMeta.contactPhone) {
      orgMeta.contactPhone = String(orgMeta.contactPhone as string);
    }
    updateVendor(this.editedVendorId, vendorToSave, vendorETag)
      .then((response) => {
        this.closeVendor();
        this.$emit("save", response);
      })
      .catch((error) => {
        this.handleVendorExtraErrors(error);
      })
      .finally(() => {
        this.vendorSaving = false;
      });
  }

  private handleVendorExtraErrors(error: any) {
    this.extraErrors = [];
    handleExtraErrors(this.arrErrors, this.extraErrors, error);
  }

  // The user must be a planner for the current organization to edit.
  private isEditable(): boolean {
    // return (this.editedVendorId === '' || this.isPlannerForCurrentOrg());
    return true;
  }

  private isOppositeItemSelected(itemNameForCategory: string, selectedValues: string[], categories: DiversityRefDataCategory[]): boolean {
    const oppositeItem: DiversityRefDataCategory | undefined = categories.find((x) => x.itemNameForCategory === itemNameForCategory);
    if (oppositeItem !== undefined) {
      return selectedValues.includes(oppositeItem.code);
    } else {
      return false;
    }
  }

  private isThirdPartyItemDisabled(item: any): boolean {
    const itemNameForCategory: string = (item.itemNameForCategory as string).replace("Certified", "Self-Declared");

    return this.isOppositeItemSelected(
      itemNameForCategory,
      this.editedItem.diversityDeclarations.socialFocusedOrgs ? this.editedItem.diversityDeclarations.socialFocusedOrgs.values : [],
      this.helper.diversityReferenceData ? this.helper.getDiversityReferenceDataCategories("socialFocusedOrgs") : [],
    );
  }

  private isSelfDeclaredItemDisabled(item: any): boolean {
    const itemNameForCategory: string = (item.itemNameForCategory as string).replace("Self-Declared", "Certified");

    return this.isOppositeItemSelected(
      itemNameForCategory,
      this.editedItem.diversityDeclarations.impactESG ? this.editedItem.diversityDeclarations.impactESG.values : [],
      this.helper.diversityReferenceData ? this.helper.getDiversityReferenceDataCategories("impactESG") : [],
    );
  }

  private updateOwnership() {
    if (this.editedItem.vendor.proxyPayeeFor) {
      const thirdParty = this.editedItem.diversityDeclarations.impactESG ? this.editedItem.diversityDeclarations.impactESG.values.find((x) => x === "indigenousOwned") : undefined;
      const selfDeclared = this.editedItem.diversityDeclarations.socialFocusedOrgs ? this.editedItem.diversityDeclarations.socialFocusedOrgs.values.find((x) => x === "indigenousFocused") : undefined;
      if (thirdParty || selfDeclared) {
        this.editedItem.diversityDeclarations.indigenousImpact = _.cloneDeep(EmptyDiversityDeclarationMeta);
      } else {
        this.editedItem.diversityDeclarations.indigenousImpact = { values: ["indigenousOwned"] };
      }
    } else {
      this.editedItem.diversityDeclarations.indigenousImpact = _.cloneDeep(EmptyDiversityDeclarationMeta);
    }
  }
}
