

















































































































































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import ImpactCategoriesComponent from "./ImpactCategoriesComponent.vue";
import ErrorComponent from "./ErrorComponent.vue";
import * as _ from "lodash";
import { CommonMeta, ResourceSearchResult } from "@/store/models";
import { connectProfile, disconnectProfile, searchNistoProfiles } from "@/store/rest.service";
import * as datetime from "../lib/datetime";

@Component({
  components: {
    ImpactCategoriesComponent,
    SpinnerComponent,
    ErrorComponent,
  },
})
export default class DiversityInfoSummaryComponent extends BaseComponent {
  @Prop() public editedItem: any;
  @Prop() public helper: any;
  @Prop() public vendorType: any;

  private formatDateForDisplay = datetime.formatDateForDisplay;
  private arrErrors: Error[] = [];
  private debounceSearch = _.debounce(this.searchProfiles, 500);
  private searchTerms: string = "";
  private searchResults: ResourceSearchResult | null = null;

  private disconnectProfileResponse: any;
  private connectProfileResponse: any;

  private profilesLoading: boolean = false;
  private disconnectLoading: boolean = false;
  private connectLoading: boolean = false;
  private searchStarted: boolean = false;

  protected mounted() {}

  private isVendorConnected(): boolean {
    return this.editedItem.resourceInfo.nistoProfiles;
  }

  private editDiversityInfo() {
    this.$emit("edit-diversity-info");
  }

  private connectProfile(profileId: string) {
    this.connectLoading = true;
    const commonMeta = this.editedItem.common as CommonMeta;
    const vendorId: string = commonMeta.identifier as string;
    const vendorEtag: string = commonMeta.etag as string;
    connectProfile(profileId, vendorId, vendorEtag)
      .then((response) => {
        this.connectProfileResponse = response;
        this.$emit("profile-connected");
      })
      .catch((error) => {
        this.arrErrors.push(error);
      })
      .finally(() => {
        this.connectLoading = false;
      });
  }

  private disconnectProfile() {
    this.disconnectLoading = true;
    const commonMeta = this.editedItem.common as CommonMeta;
    const vendorId: string = commonMeta.identifier as string;
    const vendorEtag: string = commonMeta.etag as string;
    disconnectProfile(vendorId, vendorEtag)
      .then((response) => {
        this.disconnectProfileResponse = response;
        this.$emit("profile-disconnected");
      })
      .catch((error) => {
        this.arrErrors.push(error);
      })
      .finally(() => {
        this.disconnectLoading = false;
      });
  }

  private searchProfiles() {
    if (this.searchTerms.trim() !== "" && this.searchTerms.trim().length >= 3) {
      this.searchStarted = true;
      this.profilesLoading = true;
      searchNistoProfiles(this.searchTerms)
        .then((response) => {
          this.searchResults = response;
        })
        .catch((error) => {
          this.arrErrors.push(error);
        })
        .finally(() => {
          this.profilesLoading = false;
        });
    }
  }
}
